<template>
	<lidaPage :columns="columns" ref="page" :queryInfo="queryInfo" :formColumns="formColumns" :subTitle="subTitle" :query="invoicePage">
		<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
		    <el-tab-pane :label="item.title" :name="item.type" v-for="item in navs" :key="item.type"></el-tab-pane>
		</el-tabs>
		<div class="line"></div>
		<template #auditStatus="scope">
			<el-tag type="warning" effect="dark" size="mini" v-if="scope.row.auditStatus === 'IN_PASS'">待审核</el-tag>
			<el-tag type="success" effect="dark" size="mini" v-if="scope.row.auditStatus === 'PASS'">已通过</el-tag>
			<el-tag type="danger" effect="dark" size="mini" v-if="scope.row.auditStatus === 'UN_PASS'">已驳回</el-tag>
			<el-tag type="info" effect="dark" size="mini" v-if="scope.row.auditStatus === 'UNDO'">作废</el-tag>
		</template>
		<template #operation>
			<lida-table-column label="操作" width="180" fixed="right">
				<template #default="scope">
					<el-button @click="goDetails(scope.row)" type="text">查看</el-button>
					<el-button v-if="scope.row.auditStatus === 'IN_PASS'" @click="operation(scope.row,'通过')" type="text">通过</el-button>
					<el-button v-if="scope.row.auditStatus === 'IN_PASS'" @click="operation(scope.row,'驳回')" type="text">驳回</el-button>
					<el-button @click="operation(scope.row,'作废')" type="text">作废</el-button>
				</template>
			</lida-table-column>
		</template>
	</lidaPage>
	<applyBilling :info="activeRow" v-model="show" type="detail" />
</template>

<script>
	import applyBilling from '../components/dialog/applyBilling.vue'  //申请开票
	import {
		mapActions
	} from 'vuex'
	import { ElMessage , ElMessageBox } from 'element-plus'
	export default{
		data(){
			return{
				columns:[
					{label:'买方公司名称',prop:'buyerEnterpriseName'},
					{label:'买方姓名',prop:'buyerName'},
					{label:'订单号',prop:'documentNumber'},
					{label:'公司名称',prop:'invoiceHead'},
					{label:'开票电话',prop:'invoicePhone'},
					{label:'公司地址',prop:'address'},
					{label:'开户行',prop:'bankName'},
					{label:'公司税号',prop:'taxpayerCode'},
					{label:'银行账号',prop:'bankAccount'},
					{label:'审核状态',prop:'auditStatus'}
				],
				navs:[
					{title:'我收到的',type:'0'},
					{title:'我发出的',type:'1'},
				],
				activeName:'0',
				activeRow:{},
				show:false,
			}
		},
		components:{
			applyBilling
		},
		computed:{
			queryInfo(){
				return {
					myselfFlag:this.activeName,
				}
			},
			subTitle(){
				return this.navs.filter(v => v.type === this.activeName)[0].title +  "申请数";
			}
		},
		methods:{
			...mapActions('mysaleMudule',[
				'invoicePage',
				'invoicePass',  //开票通过
				'invoiceUnPass',  //开票不通过
			]),
			goDetails(row){
				this.activeRow = row;
				this.show = true;
			},
			operation(row,type){
				ElMessageBox.confirm(
					`是否要${type}当前开票申请？`,
					"提示", {
						confirmButtonText: "确认",
						cancelButtonText: "取消",
						type: "warning"
					}
				)
				.then(() => {
					let fn = type === "通过" ? this.invoicePass : this.invoiceUnPass;
					fn({
						...row,
						auditStatus:type === '通过' ? 'PASS' : type === '驳回' ? 'UN_PASS' : type === '作废' ? 'UNDO' : undefined,
					}).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							ElMessage.success('操作成功');
							this.$refs.page.refresh();
						}
					})
				})
				.catch(() => {});
			}
		},
		watch:{
			activeName(v){
				this.$nextTick(() => {
					this.queryInfo.pageNo = 1;
					this.$refs.page.refresh();
				})
			}
		}
	}
</script>

<style>
</style>
